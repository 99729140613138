.parallax-img {
  height: 50vh;
}
.parallax-img img {
  opacity: 0.5;
}

.trial .row {
  padding-top: 3em;
}

.trial-text p {
  color: var(--color-white);
  padding: 0 20%;
}

@media (max-width: 575.98px) {
  .main-trial {
    width: auto !important;
  }
}
