nav.navbar {
  padding: 18px 0px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0;
  background-color: #00142c;
}
nav.navbar a.navbar-brand {
  width: 13%;
}
nav.navbar .navbar-nav .nav-link.nav-link {
  font-weight: 400;
  color: var(--color-white);
  letter-spacing: 0.8px;
  padding: 0 26px;
  font-size: 15px;
}
nav.navbar .navbar-nav a.nav-link.nav-link:hover,
nav.navbar .navbar-nav a.nav-link.nav-link.active {
  opacity: 1;
  color: var(--color-green);
}
span.navbar-text {
  display: flex;
  align-items: center;
}
.dropdown-text:hover {
  color: var(--color-green);
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid var(--color-white);
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid var(--color-white);
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: var(--color-white);
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded='true'] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: var(--color-white);
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded='true'] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: var(--color-white);
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded='true'] .navbar-toggler-icon {
  border-color: transparent;
}
.dropdown-box {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
}
.service-link:hover .dropdown-box {
  animation: fadeIn 0.5s ease-in-out;
}

.logo-img {
  width: 93%;
}

@media (max-width: 768px) {
  .logo-img {
    width: 130%;
  }
}
