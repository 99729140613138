.banner {
  margin-top: 0;
  padding: 260px 0px 350px 0px;
  background-image: url('../../../assets/img/bg-image.jpg');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.background-overlay {
  background-color: transparent;
  background-image: linear-gradient(
    180deg,
    var(--color-blue-tertiary) 15%,
    var(--color-blue-primary) 100%
  );
  opacity: 1;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.banner-main-text {
  margin-top: 12.5em;
}
.banner h1 {
  font-size: 55px;
}
.banner p {
  padding-right: 5em;
  color: var(--color-gray);
  width: 96%;
}
.banner img {
  margin-top: 6.875em;
}
.wrap {
  color: var(--color-green);
}

@media (max-width: 991px) and (min-width: 751px) {
  .banner p {
    padding: 0;
  }
  .banner-main-text {
    margin-top: 6.25em;
  }
}
