.contactus {
  background-color: transparent;
  background-image: linear-gradient(
    180deg,
    var(--color-blue-tertiary) 15%,
    var(--color-blue-primary) 100%
  );
}
.contactus i {
  color: var(--color-green);
}

.contactus h5 {
  color: var(--color-green);
}

.contact-icon {
  background-color: var(--color-blue-secondary);
}

.form-conteiner {
  color: var(--color-white);
}

.form-control {
  color: var(--color-white);
  border-style: solid;
  border-color: var(--color-blue-quaternary);
  background-color: var(--color-blue-secondary);
}

.form-control::placeholder {
  color: var(--color-white);
  opacity: 0.6;
}

.form-control:focus {
  background-color: transparent;
  box-shadow: none;
  outline: none;
}

.form-box {
  border-style: solid;
  border-color: var(--color-blue-quaternary);
}

textarea {
  height: 9.25em;
}

.danger {
  color: rgb(133, 0, 0);
}
.success {
  color: var(--color-green);
}
