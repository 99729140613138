.footer {
  padding-top: 5em;
}

.footer p {
  color: var(--color-white);
}

.footer-link {
  color: var(--color-white);
}
.footer-link:hover {
  color: var(--color-green);
}

.footer-border {
  border-style: solid;
  border-width: 2px 0px 0px 0px;
  border-color: var(--color-blue-border);
}

.social-icon-box a {
  margin: 0 10px;
  width: 32px;
  height: 32px;
  background-color: var(--color-green);
  transition-duration: 0.4s;
}

.social-icon-box a:hover {
  width: 28px;
  height: 28px;
  opacity: 0.8;
}

.soc-icon {
  color: var(--color-white);
  font-size: 18px;
}

@media (max-width: 992px) {
  .footer-logo {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .footer-logo {
    width: 25%;
  }
}

@media (max-width: 768px) {
  .social-icon-box {
    padding-top: 20px;
  }
}
