.protection {
  background-color: var(--color-blue-primary);
  position: relative;
}
.about-protection {
  padding-top: 15%;
  padding-bottom: 15%;
}
.about-protection h5 {
  color: var(--color-green);
}
.about-protection p {
  color: var(--color-gray);
}
.about-protection img {
  width: 40px;
  height: 40px;
}
.opportunities {
  height: 580px;
}
.opportunities img {
  object-fit: cover;
}
.opportunities p {
  color: var(--color-gray);
}

.opportunity-caption {
  top: 90%;
  left: 33%;
  transform: translate(-50%, -50%);
  background-color: var(--color-blue-primary);
}
