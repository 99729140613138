/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url('../assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url('../assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url('../assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}

/************ Custom Colors **********/
:root {
  --color-white: #ffffff;
  --color-gray: #828fa0;
  --color-green: #0ec9ac;
  --color-blue-primary: #00142c;
  --color-blue-secondary: #011936;
  --color-blue-tertiary: #00142cd6;
  --color-blue-quaternary: #10233b;
  --color-blue-border: #061f3e;
  --color-danger: #a94442;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

p {
  color: var(--color-gray);
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

.main-button {
  color: var(--color-white);
  background-color: var(--color-green);
}

input:focus,
textarea:focus {
  box-shadow: none;
}

::placeholder {
  color: var(--color-white);
}

.App {
  background-color: transparent;
  background-image: linear-gradient(
    180deg,
    var(--color-blue-tertiary) 15%,
    var(--color-blue-primary) 100%
  );
  z-index: -5;
}

@media (min-width: 1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}
